import React, { useState } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Comments from "../../components/Comments"
import ContentTypePagination from "../../components/ContentTypePagination"
import AuthorBio from "../../components/AuthorBio"
import PostMeta from "../../components/PostMeta"
import PostCategories from "../../components/PostCategories"
import FeaturedMedia from "../../components/FeaturedMedia"
import Sidebar from "../../components/Sidebar"
import Instagram from "../../components/Instagram"
import Share from "../../components/Share"
import BlankHeader from "../../components/BlankHeader"
import BlogHeader from "../../components/BlogHeader"
import useSiteMetadata from "../../utils/use-site-metadata"
import ReactDisqusComments from 'react-disqus-comments';
import { graphql, Link, useStaticQuery } from "gatsby"


const Post = ({ data, pageContext, toggleBackdrop, user, ...props }) => {
  

  var canUseDOM = !!(
    (typeof window !== 'undefined' && window.document && window.document.createElement)
  );



  const { nextPage, previousPage, page, wp, allWpCategory } = data
  const {
    title,
    content,
    featuredImage,
    categories,
    excerpt,
    databaseId,
    author,
    date,
    seo,
    uri,
  } = page
  const options = wp.acfOptionsOptions
  const { siteURL } = useSiteMetadata()
  const url = siteURL + uri
  const media = featuredImage.node.sourceUrl
  const allCategories = allWpCategory.nodes

  const HandleSearch = () => {}
  const HandleFilter = () => {}

  const [search, setSearch] = useState("")

  return (
    <Layout
      wp={wp}
      bodyClass={`post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      <Seo data={seo} />
	<BlankHeader />

      <div className="show-desktop">
	</div>

      <div className="row post-list">
        <section className="posts">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="show-mobile">
                  <Link to="/community">
                    <div className="arrow"></div>
                  </Link>
                </div>
                <article
                  className={`post-${databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
                  id={`post-${databaseId}`}
                >

                  <div className="post-inner">
                    <h6
                      className="show-desktop"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />

                    <div className="post-meta">
                      <div className="date">{date}</div>
                      <div className="author">
                        by <span>{author.node.name}</span>
                      </div>
					  {/*
			<PostCategories categories={categories} />
					  */}
			<a href = "#comments">Comments</a>
                    </div>
						<h1
							className="show-mobile"
							dangerouslySetInnerHTML={{ __html: title }}
						/>

                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
			<div className="postlink">
				<div className="auth">
          <p>
             <strong><a href="/postlink/"> Click Here</a></strong> to login and post your own Visual Forester forests.
          </p>
		</div>
		
				<a name = "comments">
				<ReactDisqusComments
   		 		shortname="visual-forester"
   				//title={post.title}
    				//url={post.url}
    				//category_id={post.category_id}
    				//onNewComment={() => alert('new comment available!'}
				/>
				</a>
			</div>

              	<Share url={url} title={title} media={media} />

		
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Sidebar 
          options={options}
          categories={allCategories}
          handleSearch={HandleSearch.bind(this)}
          handleFilter={HandleFilter.bind(this)}
          query=""
        />
      </div>	



      {/* <Instagram social={options.acfOptions.social} /> */}

    </Layout>
  )
}

export default Post

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent
      uri
      seo {
        title
        twitterDescription
        twitterImage {
          sourceUrl
        }
        metaKeywords
        metaDesc
        opengraphType
        twitterTitle
        opengraphUrl
        opengraphTitle
        opengraphSiteName
        opengraphAuthor
      }
    }
    wp {
      acfOptionsOptions {
        acfOptions {
          copy
          top {
            button
            buttonUrl
            content
            content2
            form
            title
            title2
          }
          bottom {
            button
            buttonUrl
            content
            content2
            content3
            title
            title2
            title3
            logo {
              sourceUrl
              altText
            }
            logo2 {
              sourceUrl
              altText
            }
          }
          social {
            icon {
              id
              sourceUrl
              altText
            }
            url
          }
          blogTitle
          blogContent
          gallery {
            altText
            mediaDetails {
              sizes {
                sourceUrl
              }
            }
            sourceUrl
          }
          supportContent
          supportTitle
          supportButton
          supportButtonUrl
          videos {
            videoUrl
          }
        }
      }
    }
    allWpCategory {
      nodes {
        id
        uri
        name
        slug
      }
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
