import React from "react"

const BlankHeader = () => {
  return (
    <div>
		<p>
		&nbsp;
		</p>
		<p>
		&nbsp;
		</p>
    </div>
  )
}

export default BlankHeader
