import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";

const Share = ( { url, title, media } ) => {
  return (
    <div className="share-wrap">
      <p>Share this post</p>
      <div className="share-buttons">
        <FacebookShareButton url={url} title={title}><FacebookIcon size={40} /></FacebookShareButton>
        <LinkedinShareButton url={url} title={title}><LinkedinIcon size={40} /></LinkedinShareButton>
      </div>
    </div>
  )
}

export default Share
